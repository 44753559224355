
	import PageBase from '@/Page/Base.vue';
	import PageBirthdate from '@/Page/Birthdate.vue';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project BunnyDragon
	 */
	@Component({
		components: {
			PageBirthdate,
		},
	})
	export default class PageBirthdateYear extends PageBase {
		// Not implemented
	}
